/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          // reveal
          // Changing the defaults
          window.sr = ScrollReveal({ reset: true });

          // Customizing a reveal set
          sr.reveal('h1', {
            origin: 'bottom',
            distance: '20px',
            scale: 1,
            duration: 500,
            delay: 300,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          sr.reveal('.work-large', {
            origin: 'bottom',
            distance: '20px',
            scale: 1,
            duration: 500,
            delay: 300,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          sr.reveal('a.cta', {
            origin: 'bottom',
            distance: '20px',
            scale: 1,
            duration: 500,
            delay: 500,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

           // Customizing a reveal set
          sr.reveal('.entry-title', {
            origin: 'bottom',
            distance: '20px',
            scale: 1,
            duration: 500,
            delay: 350,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

           // Customizing a reveal set
          sr.reveal('time', {
            origin: 'bottom',
            distance: '20px',
            scale: 1,
            duration: 500,
            delay: 400,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          // sr.reveal('.entry-content', {
          //   distance: '0px',
          //   scale: 1,
          //   duration: 1000,
          //   delay: 1000,
          //   opacity: 0,
          //   easing: 'ease',
          //   reset: false,
          // });

          // Customizing a reveal set
          sr.reveal('img', {
            distance: '0px',
            scale: 1,
            duration: 1000,
            delay: 200,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          sr.reveal('.image', {
            distance: '0px',
            scale: 1,
            duration: 1000,
            delay: 100,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          sr.reveal('.nf-form-cont', {
            distance: '0px',
            scale: 1,
            duration: 1000,
            delay: 200,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          // Customizing a reveal set
          sr.reveal('.last', {
            distance: '0px',
            scale: 1,
            duration: 1000,
            delay: 200,
            opacity: 0,
            easing: 'ease',
            reset: false,
          });

          $('.menu-toggle').on('click', function() {
            $('.global--nav--full').toggleClass('fadeinout');
            $('.menu-toggle').toggleClass('wht');
          })

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
